<template>
  <vx-card style="padding-left: 10px">
    <div class="vx-row flex mb-2">
      <div class="vx-col w-full" align="right">
        <vs-button
          align="right"
          @click="$router.push({name:'create-app-user'})"
          icon-pack="feather"
          icon="icon-edit"
        >Create Nurse/Doctor</vs-button>
      </div>
    </div>

    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="users"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
        search
      >
        <div slot="header" class="flex flex-wrap-reverse flex-grow justify-between">
          <vs-select placeholder="10" vs-multiple autocomplete v-model="dataTableParams.limit">
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="item.text"
              v-for="(item,index) in limitOptions"
              :clearable="false"
            />
          </vs-select>
          <vs-select vs-multiple autocomplete v-model="dataTableParams.user" class="mr-6">
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="item.text"
              v-for="(item,index) in userOptions"
            />
          </vs-select>
        </div>

        <template slot="thead">
          <vs-th sort-key="userType">User Type</vs-th>
          <vs-th sort-key="firstName">First Name</vs-th>
          <vs-th sort-key="lastName">Last Name</vs-th>
          <vs-th sort-key="email">Email Name</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].userType">{{ data[indextr].userType | capitalize}}</vs-td>

            <vs-td :data="data[indextr].firstName">{{ data[indextr].firstName | capitalize}}</vs-td>

            <vs-td :data="data[indextr].lastName">{{ data[indextr].lastName | capitalize}}</vs-td>

            <vs-td :data="data[indextr].email">{{ data[indextr].email }}</vs-td>

            <vs-td :data="data[indextr]._id">
              <vx-tooltip text="View/Edit Details">
                <vs-button
                  type="border"
                  size="small"
                  @click="editDetailHandler(data[indextr]._id)"
                  icon-pack="feather"
                  icon="icon-edit"
                  class="m-1"
                ></vs-button>
              </vx-tooltip>
              <assign-nurse
                class="m-1"
                v-if="data[indextr].userType=='nurse'"
                :nurseId="data[indextr]._id"
                :associatedIds="data[indextr].associatedClinicIds.map((e) => e.clinicId)"
              />
              <request-doctor
                class="m-1"
                v-if="data[indextr].userType==='doctor'"
                :doctorId="data[indextr]._id"
              />
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <!--      <span class="mr-2" >{{ currentPage * dataTableParams.limit - (dataTableParams.limit - 1) }} - {{ totalDocs - currentPage * dataTableParams.limit > 0 ? currentPage * dataTableParams.limit : totalDocs }} of {{ totalDocs }}</span>-->
      <!--      <vs-pagination :total="totalPage" v-model="dataTableParams.page"></vs-pagination>-->
      <div class="m-2">
        <span class="mr-2">
          {{
          dataTableParams.page * dataTableParams.limit -
          (dataTableParams.limit - 1)
          }}
          -
          {{
          totalDocs - dataTableParams.page * dataTableParams.limit > 0
          ? dataTableParams.page * dataTableParams.limit
          : totalDocs
          }}
          of {{ totalDocs }}
        </span>
        <div class="pagination-div" v-if="serverResponded">
          <paginate
            :page-count="totalPage"
            :click-handler="handleChangePage"
            class="pagination"
            :page-range="9"
            :prevText="'<'"
            :nextText="'>'"
          ></paginate>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import assignNurse from "../../components/others/assignNurse";
import requestDoctorAssociation from "../../components/others/requestDoctorAssociation";

export default {
  components: {
    "assign-nurse": assignNurse,
    "request-doctor": requestDoctorAssociation
  },
  data() {
    return {
      isMounted: false,
      totalDocs: 0,
      currentPage: 1,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 25,
        userType: "appUser",
        user: ""
      },
      serverResponded: false,
      users: [],
      limitOptions: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 }
      ],
      userOptions: [
        { text: "All Users", value: "" },
        { text: "Doctor", value: "doctor" },
        { text: "Nurse", value: "nurse" }
      ]
    };
  },
  methods: {
    ...mapActions("appUser", ["fetchAppUsers"]),
    moment(date) {
      return moment(date);
    },
    getUsersList() {
      let self = this;
      this.fetchAppUsers(self.dataTableParams).then(res => {
        self.users = res.data.data.docs;
        self.totalDocs = res.data.data.pagination.total;
        self.page = res.data.data.pagination.page;
      });
      this.serverResponded = true;
    },
    handleSearch(searching) {
      this.serverResponded = false;
      this.dataTableParams.search = searching;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getUsersList();
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getUsersList();
    },
    handleSort(key, active) {
      this.serverResponded = false;
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getUsersList();
    },
    editDetailHandler(id) {
      this.$router.push({ name: "edit-app-user", params: { id: id } });
    }
  },
  watch: {
    "dataTableParams.page": function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getUsersList();
      }
    },
    "dataTableParams.limit": function(newLimit, oldLimit) {
      if (newLimit !== oldLimit) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newLimit;
        this.getUsersList();
      }
    },
    "dataTableParams.user": function(newUser, oldUser) {
      if (newUser !== oldUser) {
        this.dataTableParams.user = newUser;
        this.getUsersList();
      }
    }
  },
  computed: {
    totalPage: function() {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    }
  },
  created() {
    this.getUsersList();
  }
};
</script>
